import {PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import {Week} from "@/constants/DateTimeConstants";

export default class TarifficationDto {
    public payPeriodType: PayPeriodType | null = null;
    public payType: PayType | null = null;
    public taxDefs: Array<string> = []
    public rate!: number
    public overtimeRate = 1.5
    public payPeriodStart: Week | null = null;
    public payPeriodEnd: Week | null = null;
    public zoneId: string | null = null
}

export class UpdateTarifficationPayload {
    taxDefinitions: Array<string> = []
    taxHolderId!: number
    rate!: number
    payType: PayType | null = null;
    payPeriodType: PayPeriodType | null = null;
    shouldCalculateOverwork = true;
    overtimeRate = 1.5
    payPeriodStart: Week | null = null;
    payPeriodEnd: Week | null = null;
    zoneId: string | null = null
}

export class OverrideTaxRatePayload {
    taxHolderId: number | null = null;
    amount: number | null = null
    taxRate: number | null = null;
    taxUuid: string | null = null
}
