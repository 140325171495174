
































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {PayType} from "@/constants/TarifficationConstants";
import CreateDemoPayrollDetailsPayload from "@/dto/payroll/PayrollDTO";
import moment, {Moment} from "moment";
import Application from "@/state/Application";
import PayrollService from "@/services/PayrollService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";

@Component({
	components: {PortalDate, PortalInput}
})
export default class CreateDemoPaystubModal extends Vue {
	@Prop()
	private employmentId!: number

	@Prop({default: PayType.HOUR_RATE})
	private mode!: PayType

	@Prop()
	private callback!: (req: CreateDemoPayrollDetailsPayload) => void

	private infoReq = new CreateDemoPayrollDetailsPayload()

	private req = new CreateDemoPayrollDetailsPayload()

	public created(): void {
		this.req.employmentId = this.employmentId;
		this.infoReq.employmentId = this.employmentId
	}

	private loadPayPeriodInfo() {
		if (this.req.payPeriodStart && this.req.payPeriodEnd) {
			this.infoReq.payPeriodEnd = this.req.payPeriodEnd;
			this.infoReq.payPeriodStart = this.req.payPeriodStart;
			Application.startLoading()
			PayrollService.getPaystubPeriodDetails(this.infoReq).then(
				res => {
					this.req.regularHours = res.data.regularHours != 0 ? res.data.regularHours : null
					this.req.overtimeHours = res.data.overtimeHours != 0 ? res.data.overtimeHours : null
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		}
	}

	private create(): void {
		ifValid(this, () => {
			this.$modal.hideAll();
			this.callback(this.req);
		})
	}
}
